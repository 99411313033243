import React from 'react'
import { Container, Box } from '@mui/material'
import Footer from './Footer'
export default function MisionVision() {
    return (
        <Container>
            <Box p={5}></Box>
            <h1>Misión y Visión</h1>

            <h2>1. Tu voz es tu poder: <em>¡Únete para un cambio significativo con Polivoz!</em></h2>
            <p>Únete a otros miembros de la comunidad para aprovechar la fuerza de la voz colectiva. Con Polivoz, puedes transformar tus preocupaciones individuales en un movimiento poderoso que inspire un cambio real y significativo en tu ciudad, provincia y país. Juntos podemos llevar a Ecuador al siguiente nivel.</p>

            <h2>2. Rompe el silencio, rompe las cadenas: <em>¡Conviértete en un catalizador de la transformación con Polivoz!</em></h2>
            <p>Entra en una comunidad vibrante donde la voz de cada persona es importante. En Polivoz, te animamos a alzar tu voz contra el statu quo y a ser parte de un movimiento dinámico que busca fomentar la transformación. Al liberarte del silencio, puedes inspirar a quienes te rodean a unirse a un grupo de líderes que buscan crear un cambio positivo e impactante.</p>

            <h2>3. No más mentiras, solo voces auténticas: <em>¡Eleva el diálogo con Polivoz!</em></h2>
            <p>Es hora de amplificar tus verdaderas opiniones y compartir experiencias honestas sin miedo. Polivoz ofrece una plataforma para voces auténticas, donde puedes participar en debates abiertos, enfrentar la desinformación y mejorar las perspectivas de los demás. Juntos, podemos crear una comprensión más clara de los problemas que más nos importan.</p>

            <h2>4. Juntos nos levantamos: <em>¡Transforma la frustración en acción con propósito en Polivoz!</em></h2>
            <p>Únete a una comunidad solidaria donde las frustraciones compartidas pueden convertirse en la base de una acción con propósito. Polivoz te invita a conectarte con otras personas que sienten lo mismo y a trabajar colectivamente para encontrar soluciones que generen un cambio positivo. Juntos podemos convertir el descontento en una fuerza para el bien sin recurrir a la violencia.</p>

            <h2>5. De las quejas a las ideas: <em>¡Empodera a tu comunidad a través del diálogo abierto con Polivoz!</em></h2>
            <p>Toma la iniciativa de apoyar y empoderar a tu comunidad fomentando conversaciones abiertas y constructivas con Polivoz. Esta plataforma fomenta el debate y permite que tu voz se escuche, convirtiendo las quejas en soluciones colaborativas. Interactuemos entre nosotros, compartamos ideas y construyamos juntos un Ecuador más fuerte.</p>
            <Footer />
        </Container>
    )
}