import React, { useEffect, useState } from 'react'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../firebase'
import { useParams } from 'react-router-dom'
import moment from 'moment';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Card, CardContent, CardMedia, Container, Typography, CardActions, IconButton, CardHeader, Avatar, Box, CircularProgress } from '@mui/material'
export default function PostsPage() {
  const params = useParams()
  const [loading, setLoading] = useState(false)
  const [post, setPost] = useState(null)
  useEffect(() => {
    profile_db()
  },[])
  const profile_db = async () => {
    setLoading(true)
    const docRef = doc(db, `users/${params.username}/posts/${params.postId}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const doc_data = docSnap.data()
      setPost(doc_data)
      setLoading(false)
      //console.log('profile screen:', JSON.stringify(doc_data, null, 2))
    } else {
      console.log("No such document!");
    }
  
  }
  return (
    <Box>
   
      <Typography align='center' variant='h4'><strong>Polivoz</strong> </Typography>
      <Container maxWidth='sm'>
        <Box p={2}></Box>
        {
          !loading ?
            <Card variant='outlined'>
              <CardHeader
                avatar={
                  <Avatar src={post?.user_photo} aria-label={post?.username} />
                }
                action={
                  <IconButton aria-label="settings">
                    <MoreVertIcon />
                  </IconButton>
                }
                title={post?.username}
                subheader={`${post?.post_province} ${moment(post?.created?.toDate().toISOString()).fromNow()}`}
              />
              <CardContent>
                <Typography>{post?.post_description}</Typography>
              </CardContent>
              <CardMedia
                component="img"
                image={post?.post_photo}
                height='100%'
                alt={post?.post_description}
              />
              <CardActions disableSpacing>
                <IconButton aria-label="add to favorites">
                  <FavoriteBorderIcon />
                </IconButton>
                <IconButton aria-label="share">
                  <ChatBubbleOutlineIcon />
                </IconButton>
              </CardActions>
            </Card>
            : <CircularProgress />
        }
      </Container>
    </Box>

  )
}