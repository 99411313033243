import React from 'react'
import { Container, Typography, Box } from '@mui/material'
import logo from '../assets/titulonuevo.png'
import CheckoutForm from '../CheckoutForm'
import Footer from './Footer'
export default function Home() {
    return (
        <Container>
            <Box p={5}></Box>
          <img style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '80%'
            }} src={logo} alt='polivoz' />
            {/*   <Typography align='center' variant='h1'><strong>Polivoz</strong></Typography> */}
          <Typography align='center' color='grey' variant='h4'><strong>De las quejas a las ideas. Tu voz, tu poder.</strong></Typography>
            <Typography align='center' color='grey' variant='h4'><strong>¡Únete a Polivoz y lleva a Ecuador al siguiente nivel!</strong></Typography>
           <Container maxWidth="xs">
            <Box p={2}></Box>
           <CheckoutForm />
           </Container>
            <Footer />
        </Container>
    )
}